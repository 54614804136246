import anime from 'animejs/lib/anime.es.js'
const jquery = require("jquery")
window.$ = window.jQuery = jquery

const data = require('../data.yml')

var animations = {}

function ready(fn) {
    if ( document.readyState === 'complete' || document.readyState === 'interactive') {
        setTimeout(fn, 1)
    } else {
        document.addEventListener('DOMContentLoaded', fn)
    }
}

function main() {
    // Ready
    if (document.querySelector('#backdrop')) {
        animations = prepareAnimations()
        document.querySelector('#pre-headline').onclick = function() {
            animations.ringBlue.play()
            animations.ringYellow.play()
        }
        setTimeout(function () {
            animations.ringBlue.play()
            animations.ringYellow.play()
        }, 500)
        listenToKeyEvents()
    }
    $('#newsletter-signup').on("submit", function(event) {
        event.preventDefault()
        var $form = $(this)
        submitForm($form)
    })
}

ready(main)

function prepareAnimations() {
    var amplitude = '10' // 1-10   The larger this number, the more overshoot there is
    var period = '1.5'  // 0.1-2  The smaller this number, the more times the curtain goes back and forth.
    var easing = `easeOutBack(${amplitude}, ${period})`
    //var easing = `spring(70, 100, 99, 0)` // (mass, stiffness, damping, velocity)
    var animations = {
        ringBlue: anime({
            targets: '#ring-blue',
            duration: 3200,
            translateX: Math.floor(window.innerWidth / 4),          // 1/4 screen to the right
            translateY: Math.floor(window.innerHeight * 3/4) + 115,     // 4/5 screen down 
            easing: easing,
            autoplay: false
        }),
        ringYellow: anime({
            targets: '#ring-yellow',
            duration: 3500,
            translateX: Math.floor(window.innerWidth / -4),
            translateY: Math.floor(window.innerHeight / 3),
            easing: easing,
            autoplay: false
        })
    }
    return animations
}

function listenToKeyEvents() {
    document.addEventListener('keyup', (event) => {
        //console.log('keyup ->', event)
        if (document.activeElement !== document.querySelector('#mce-EMAIL')) {
            if (event.key == 'a') {
                animations.ringBlue.play()
                animations.ringYellow.play()
            }
        }
    })
}

function submitForm($form) {
    $form = $form || $('#newsletter-signup')
    //console.debug('$form, $form.serialize():', $form, $form.serialize())
    $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize(),
        cache: false,
        dataType: 'json',
        contentType: "application/json; charset=utf-8",
        error: (error) => {
            console.log('Error', error)
            $('#main-headline').html(data.home.signUp.messages.error)
            $('#signup-cta').html(data.home.signUp.messages.errorSubline)
        },
        success: (res) => {
            console.log('AJAX call successful:', res)
            $('#mce-EMAIL').val('')
            if (res.result === 'error') {
                $('#main-headline').html(data.home.signUp.messages.error)
                $('#signup-cta').html(res.msg)
            } else {
                $('#main-headline').html(data.home.signUp.messages.success)
                $('#signup-cta').html(data.home.signUp.messages.successSubline)
            }
        }
    })
}
